import WorkspaceSelector from "./WorkspaceSelector";
import TasksTable from "./TasksTable";
import {Button, ModalBase} from "@livechat/design-system";
import CreateTaskForm from "./CreateTaskForm";

const TasksListHandles = ({isShowMoreVisible, getTasksList, createTaskModalOpen, toggleCreateTaskModal, selectedWorkspace, userProfile, toastVisible, toggleToastVisible, toastText, setToastText, usersList}) => {
    return (
        <div className="component__wrapper flex--row--space-around">
            <Button kind="primary" disabled={!isShowMoreVisible} onClick={()=>getTasksList(false)}>Load more</Button>
            <Button kind="secondary" onClick={()=>toggleCreateTaskModal(!createTaskModalOpen)} disabled={!selectedWorkspace} >Create task</Button>
            {createTaskModalOpen ? <ModalBase onClose={()=>{toggleCreateTaskModal(!createTaskModalOpen)}} className="modal"><div style={{margin: 'auto'}}><CreateTaskForm usersList={usersList} selectedWorkspace={selectedWorkspace} createTaskModalOpen={createTaskModalOpen} toggleCreateTaskModal={toggleCreateTaskModal} userProfile={userProfile} getTasksList={getTasksList} toastVisible={toastVisible} toggleToastVisible={toggleToastVisible} toastText={toastText} setToastText={setToastText}/></div></ModalBase> : null}
        </div>
    )
}

const AppBody = ({asanaAuthenticated, toastText, userProfile, isShowMoreVisible, workspaces, setWorkspaces, getWorkspaces, workspacesLoader, selectedWorkspace, setSelectedWorkspace, getTasksList, tasksList, isTasksFetchCompleted, setTaskModalOpen, createTaskModalOpen, toggleCreateTaskModal, toastVisible, toggleToastVisible, setToastText, setUsersList, usersList, taskModalOpen}) => {
    return(
        <div style={{width:'100%'}}>
            <WorkspaceSelector
                workspaces={workspaces}
                setWorkspaces={setWorkspaces}
                getWorkspaces={getWorkspaces}
                workspacesLoader={workspacesLoader}
                selectedItem={selectedWorkspace}
                setSelctedItem={setSelectedWorkspace}
                getTasksList={getTasksList}
                setUsersList={setUsersList}
            >
            </WorkspaceSelector>
            <div className="component__wrapper h--480">
                {selectedWorkspace === null && asanaAuthenticated ?
                    <div className="tasks-list__empty-state--placeholder">
                        <div>No tasks to display.</div>
                        <div>Select your workspace first</div>
                    </div>
                    : <TasksTable getTasksList={getTasksList} tasksList={tasksList} isTasksFetchCompleted={isTasksFetchCompleted} createTaskModalOpen={createTaskModalOpen} toastVisible={toastVisible} toggleToastVisible={toggleToastVisible} setToastText={setToastText} usersList={usersList} setTaskModalOpen={setTaskModalOpen} taskModalOpen={taskModalOpen}/>}
            </div>
            <TasksListHandles usersList={usersList} isShowMoreVisible={isShowMoreVisible} getTasksList={getTasksList} createTaskModalOpen={createTaskModalOpen} toggleCreateTaskModal={toggleCreateTaskModal} selectedWorkspace={selectedWorkspace} userProfile={userProfile} toastVisible={toastVisible} toggleToastVisible={toggleToastVisible} toastText={toastText} setToastText={setToastText}/>
        </div>
    )
}

export default AppBody
