import {CheckboxField, DatePicker, Button, TextAreaField, Loader, Select} from "@livechat/design-system";
import asana from 'asana';
import {useState, useEffect} from 'react';

const CreateTaskForm = ({createTaskModalOpen, toggleCreateTaskModal, userProfile, getTasksList, toastVisible, toggleToastVisible, setToastText, usersList}) => {
    const [datePickerVisible, setDatePickerVisible] = useState(false)
    const [taskName, setTaskName] = useState('')
    const [taskDescription, setTaskDescription] = useState('')
    const [completed, toggleCompleted] = useState(false)
    const [dueDate, setDueDate] = useState('')
    const [loaderVisible, setLoaderVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [assigneeId, setAssigneeId] = useState(null)
    const [createPrivateTask, toggleCreatePrivateTask] = useState(false)
    const [localUserList, setLocalUserList] = useState(usersList)

    useEffect(()=>{
        if(localStorage.getItem('isPersonalWorkspace')==='true'){
            toggleCreatePrivateTask(true);
            setLocalUserList([{key: localStorage.getItem('userName'), props: {value: localStorage.getItem('userId'), name: localStorage.getItem('userName')}}])
            setSelectedUser(localStorage.getItem('userName'))
            }
    }, [])

    const handleDayClick = (day) => {
        setDueDate(day.toISOString().substring(0,10))
        setDatePickerVisible(false)
      };


    const handleNameChange = (event) => {
        setTaskName(event.target.value)
    }
    const handleDescriptionChange = (event) => {
        setTaskDescription(event.target.value)
    }
    const handleSubmit = (event, createTaskModalOpen, toggleCreateTaskModal, userProfile, getTasksList, toastVisible, toggleToastVisible, loaderVisible, setLoaderVisible, setToastText) => {
        event.preventDefault();
        setLoaderVisible(!loaderVisible);
        const personalToken = localStorage.getItem('personalToken');
        const client = asana.Client.create().useAccessToken(personalToken);
        let finalAssigneeId;
        if(createPrivateTask===true){
            finalAssigneeId=localStorage.getItem('userId')} else {finalAssigneeId=assigneeId}
        const data = {name: taskName, completed: completed, due_on: dueDate, assignee:finalAssigneeId, notes:taskDescription, workspace:localStorage.getItem('workspaceId'), pretty: true}
        client.tasks.createTask(data).then((result)=>{
            toggleCreateTaskModal(!createTaskModalOpen)
            getTasksList(true)
            toggleToastVisible(!toastVisible)
            setToastText('Task created sucessfully!')
        })

      }

    const handleItemSelect = item => {
        setSelectedUser(item)
    };

    const getItemBody = props => {
        if (!props) {
            return null;
        }
        return <div id={props.value}>{props.name}</div>;
    };

    const getSelectedItemBody = props => {
        setAssigneeId(props.value)
        return <div id={props.value}>{props.name}</div>;
    };

    return (
        <div>
        {loaderVisible?
        <Loader label="Creating task..."/>
            :
            <form onSubmit={e=>handleSubmit(e, createTaskModalOpen, toggleCreateTaskModal, userProfile, getTasksList, toastVisible, toggleToastVisible, loaderVisible, setLoaderVisible, setToastText)} style={{width: "300px"}}>
            {datePickerVisible?
            <div className={'flex--column'}>
                <h2 className="lc-field-label">Select due date</h2>
                <DatePicker onDayClick={handleDayClick} selectedDays={dueDate}/>
                <Button kind="secondary" onClick={()=>{setDatePickerVisible(false)}}>Back</Button>
            </div>
            :
            <div style={{width: "300px"}}>
            <TextAreaField
            onChange={event => handleNameChange(event)}
            id="taskName"
            labelText="Task Name"
            value={taskName}
            fieldClassName="input-width--90p"
            />
            <TextAreaField
                onChange={event => handleDescriptionChange(event)}
                id="taskDescription"
                labelText="Description"
                value={taskDescription}
                fieldClassName="input-width--90p"
            />
            <Select
                id='assigneeSelector'
                items={localUserList}
                searchProperty='name'
                onItemSelect={handleItemSelect}
                getItemBody={getItemBody}
                search
                disabled={createPrivateTask}
                placeholder='Select assignee'
                getSelectedItemBody={getSelectedItemBody}
                selected={selectedUser}
            />
            <CheckboxField
            checked={completed}
            onClick={()=>toggleCompleted(!completed)}
            className={'margin--bottom--20'}
            >
                Completed
            </CheckboxField>
            <div className="modal-form--date-container" onClick={()=>{setDatePickerVisible(true)}}>Due date: <Button kind="secondary">{dueDate ? dueDate : "Set due date"}</Button></div>
            <div className="flex--row--space-around">
                <button className="lc-btn lc-btn--primary">Create task</button>
            </div>
            </div>
            }
        </form>
    }
    </div>
    )
}

export default CreateTaskForm
