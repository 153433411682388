const Loader = () => {
    return (
        <div className='loader__container'>
            <div className="lc-loader-wrapper loader__wrapper">
                <div className="lc-loader-spinner-wrapper lc-loader-spinner-wrapper--large">
                    <div className="lc-loader-spinner lc-loader-spinner--thick"/>
                </div>
            </div>
        </div>
    )
}

export default Loader;