import {CheckboxField, ModalBase, DatePicker, Button, TextAreaField, Loader, Select} from "@livechat/design-system";
import React, { useState, useEffect} from "react";
import asana from 'asana';

const TasksList = ({items, setTaskModalOpen, taskModalOpen}) => {
    let [tasks] = useState(items)

    const handleCLick = (item) => {
        setTaskModalOpen(!taskModalOpen)
        localStorage.setItem('selectedItem', JSON.stringify(item))
    }
    return (
        tasks.length > 0 ?
        tasks.map(item =>
                <div className="tasks-list__item__wrapper" key={item.gid} onClick={()=>handleCLick(item)}>
                    <CheckboxField checked={item.completed} disabled={true}> </CheckboxField>
                    <div className='tasks-list__item__body'>
                        <div className="tasks-list__item__title">{item.name}</div>
                        <div className='tasks-list__item__details-container'>
                            <div>{localStorage.getItem('isPersonalWorkspace')? localStorage.getItem('userName') : item.assigneeName}</div>
                            <div>Due on: {item.due_on ? item.due_on : null}</div>
                        </div>
                    </div>
                </div>
            )
            : <div className="tasks-list__empty-state--placeholder">
            <span>There are no tasks on this list.</span>
            <span>Add a new one or choose another workspace</span>
            </div>
    )
}

const TaskForm = ({taskModalOpen, setTaskModalOpen, task, toggleTask, getTasksList, updating, toggleUpdating, toastVisible, toggleToastVisible, setToastText, usersList}) => {
    const [datePickerVisible, setDatePickerVisible] = useState(false)
    const [dueDate, setDueDate] = useState(JSON.parse(localStorage.getItem('selectedItem')).due_on)
    const [taskName, setTaskName] = useState(JSON.parse(localStorage.getItem('selectedItem')).name)
    const [completedAt] = useState(JSON.parse(localStorage.getItem('selectedItem')).completed_at)
    const [taskDescription, setTaskDescription] = useState(JSON.parse(localStorage.getItem('selectedItem')).notes)
    const [loaderVisible, toggleLoaderVisible] = useState(false)
    const [loaderLabel, setLoaderLabel] = useState('empty')
    const [isCompleted, toggleIsCompleted] = useState(JSON.parse(localStorage.getItem('selectedItem')).completed)
    const [selectedUser, setSelectedUser] = useState(null)
    const [assigneeId, setAssigneeId] = useState(null)
    const [localUserList, setLocalUserList] = useState(usersList)
    const [createPrivateTask, toggleCreatePrivateTask] = useState(false)

    useEffect(()=>{
        setSelectedUser(JSON.parse(localStorage.getItem('selectedItem')).assigneeName)
        if(localStorage.getItem('isPersonalWorkspace')==='true'){
            setLocalUserList([{key: localStorage.getItem('userName'), props: {value: localStorage.getItem('userId'), name: localStorage.getItem('userName')}}])
            toggleCreatePrivateTask(true);
            setSelectedUser(localStorage.getItem('userName'));
        }
    },[])

    const handleDayClick = (day) => {
        setDueDate(day.toISOString().substring(0,10))
        setDatePickerVisible(false)
      };
      const handleSubmit = (event, taskModalOpen, setTaskModalOpen, loaderVisible, toggleLoaderVisible, loaderLabel, setLoaderLabel, toggleUpdating, updating, toastVisible, toggleToastVisible, setToastText) => {
        toggleUpdating(!updating)
        event.preventDefault();
        toggleLoaderVisible(!loaderVisible);
        setLoaderLabel('Updating your task...')
        const personalToken = localStorage.getItem('personalToken');
        const client = asana.Client.create().useAccessToken(personalToken);
        const data = {name: taskName, completed: isCompleted, due_on: dueDate, assignee:assigneeId, notes: taskDescription, pretty: true}
          let tempTask;
          tempTask = task;
          tempTask.completed = isCompleted
        if(isCompleted)
        {
            tempTask.completed_at = new Date().toISOString()
        }
        client.tasks.updateTask(task.gid, data)
            .then((result) => {
                setTaskModalOpen(!taskModalOpen)
                getTasksList(true)
                toggleUpdating(!updating)
                toggleToastVisible(!toastVisible)
                setToastText('Task updated successfully!')
            });
      }

    const handleNameChange = (event) => {
        setTaskName(event.target.value)
    }
    const handleDescriptionChange = (event) => {
        setTaskDescription(event.target.value)
    }


    const onDeleteClickHandler = (taskId, taskModalOpen, setTaskModalOpen, getTasksList, loaderVisible, toggleLoaderVisible, loaderLabel, setLoaderLabel, toastVisible, toggleToastVisible, setToastText) => {
        toggleLoaderVisible(!loaderVisible);
        setLoaderLabel('Deleting your task...')
        const personalToken = localStorage.getItem('personalToken');
        const client = asana.Client.create().useAccessToken(personalToken);
        client.tasks.deleteTask(taskId)
        .then(() => {
            getTasksList(true)
            setTaskModalOpen(!taskModalOpen)
            toggleToastVisible(!toastVisible)
            setToastText('Task deleted successfully!')
         });
    }

    const handleItemSelect = item => {
        setSelectedUser(item)
    };

    const getItemBody = props => {
        if (!props) {
            return null;
        }
        return <div id={props.value}>{props.name}</div>;
    };

    const getSelectedItemBody = props => {
        setAssigneeId(props.value)
        return <div id={props.value}>{props.name}</div>;
    };

    return(
    <div>
    {loaderVisible?
    <Loader label={loaderLabel}/>
        :
        <form onSubmit={e=>handleSubmit(e, taskModalOpen, setTaskModalOpen, loaderVisible, toggleLoaderVisible, loaderLabel, setLoaderLabel, toggleUpdating, updating, toastVisible, toggleToastVisible, setToastText)} style={{width: "300px"}}>
            {datePickerVisible?
            <div className={'flex--column'}>
                <h2 className="lc-field-label">Select due date</h2>
                <DatePicker onDayClick={handleDayClick} selectedDays={dueDate}/>
                <Button kind="secondary" onClick={()=>{setDatePickerVisible(false)}}>Back</Button>
            </div>
            :
            <div style={{width: "300px"}}>
            <TextAreaField
            onChange={event => handleNameChange(event)}
            id="taskName"
            labelText="Task Name"
            value={taskName}
            fieldClassName="input-width--90p"
            />
            <TextAreaField
                onChange={event => handleDescriptionChange(event)}
                id="taskDescription"
                labelText="Description"
                value={taskDescription}
                fieldClassName="input-width--90p"
            />
            <Select
                id='assigneeSelector'
                items={localUserList}
                searchProperty='name'
                onItemSelect={handleItemSelect}
                getItemBody={getItemBody}
                search
                disabled={createPrivateTask}
                placeholder='Select assignee'
                getSelectedItemBody={getSelectedItemBody}
                selected={selectedUser}
            />
            <CheckboxField
            checked={isCompleted}
            onClick={()=>{toggleIsCompleted(!isCompleted)}}
            className={'margin--bottom--20'}
            >
                Completed
            </CheckboxField>
            <div style={{display:"flex", flexDirection:"column"}}>
                <div className="modal-form--date-container cursor__pointer" onClick={()=>{setDatePickerVisible(true)}}>Due date: <Button kind="secondary"> {dueDate ? dueDate : "Set a due date"}</Button></div>
                <div className="modal-form--date-container">Completed at:{completedAt ? completedAt.substring(0,16).replace('T', ' ') + ' GMT'  : ''}</div>
            </div>
            <div className="flex--row--space-around">
                <Button kind="destructive" onClick={()=>{onDeleteClickHandler(task.gid, taskModalOpen, setTaskModalOpen, getTasksList, loaderVisible, toggleLoaderVisible, loaderLabel, setLoaderLabel, toastVisible, toggleToastVisible, setToastText)}}>Delete task</Button>
                <button className="lc-btn--primary lc-btn">Save changes</button>
            </div>
            </div>
            }
        </form>
    }
    </div>
    )
}

const TasksTable = ({getTasksList, tasksList, isTasksFetchCompleted, taskModalOpen, setTaskModalOpen, setToastText, toastVisible, toggleToastVisible, usersList}) =>  {
    const [task, setTask] = useState(JSON.parse(localStorage.getItem('selectedItem')))
    const [updating, toggleUpdating] = useState(false)

    const [tasks, setTasks] = useState(tasksList)

    useEffect(() => {
        const tempTasks = tasksList.map((item) => {
            return {
                ...item,
                assigneeName: usersList.find((obj) => {
                    return obj.props.value === item.assignee.gid;
                })?.key
            }
        });
        setTasks(tempTasks);
    }, [tasksList, usersList]);


    const handleToggleTask = () => {
        const updatedTask = {
            ...task,
            completed: !task.completed
        }
        setTask(updatedTask)
    }

    return (
        <div>
        {taskModalOpen && (
        <ModalBase onClose={()=>{setTaskModalOpen(!taskModalOpen)}} className="modal" task={task} ><div style={{margin: 'auto'}}><TaskForm taskModalOpen={taskModalOpen} setTaskModalOpen={setTaskModalOpen} task={task} getTasksList={getTasksList} toggleTask={handleToggleTask} updating={updating} toggleUpdating={toggleUpdating} toastVisible={toastVisible} toggleToastVisible={toggleToastVisible} setToastText={setToastText} usersList={usersList}/></div></ModalBase>
        )}
        {isTasksFetchCompleted ? <TasksList items={tasks} setTaskModalOpen={setTaskModalOpen} taskModalOpen={taskModalOpen} usersList={usersList}/> : <div className="tasks-list__empty-state--placeholder"><Loader/></div>}
        </div>
    )
}
export default TasksTable;
