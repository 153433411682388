import {Select} from "@livechat/design-system";
import Loader from './Loader';
import asana from "asana";

const WorkspaceSelector = ({workspaces, workspacesLoader, selectedItem, setSelctedItem, getTasksList, setUsersList}) => {

    const getUsers = async (token,workspaceGid) => {
        const client = asana.Client.create().useAccessToken(token);
        let tempList = [];
        try {
            await client.users.getUsersForWorkspace(workspaceGid, {opt_pretty: true})
                .then((result) => {
                    result.data.forEach((value) =>{tempList.push({key: value.name, props: {name:value.name, value: value.gid }})})
                    setUsersList(tempList)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleItemSelect = async item => {
        if(item){
            let tempWorkspace = workspaces.find(x => x.key === item)
            let tempWorkspaceId = tempWorkspace.props.value;
            localStorage.setItem('workspaceId', tempWorkspaceId);
            await getUsers(localStorage.getItem('personalToken'), tempWorkspaceId)
            if (tempWorkspace.key !== 'Personal Projects') {
                localStorage.setItem('isPersonalWorkspace', false)
            } else {
                localStorage.setItem("isPersonalWorkspace", true)
            }
        } else {
            localStorage.removeItem('isPersonalWorkspace')
        }
        setSelctedItem(item)
        getTasksList(true)
    };

    const getItemBody = props => {
    if (!props) {
        return null;
    }
    return <div id={props.value}>{props.name}</div>;
    };

    const getSelectedItemBody = props => {
    return <div id={props.value}>{props.name}</div>;
    };
    return (
        <div className="component__wrapper" style={{minHeight: '140px'}}>
            {selectedItem === null ?
            <h3 style={{minHeight: '44px'}}>Select workspace you'd like to manage</h3> :
            <h3>Tasks from your {selectedItem} workspace</h3>
            }
            {workspacesLoader ? <Loader/> :
            <Select
                id='workspaceSelector'
                items={workspaces}
                searchProperty='name'
                onItemSelect={handleItemSelect}
                getItemBody={getItemBody}
                search
                placeholder='Select workspace'
                getSelectedItemBody={getSelectedItemBody}
                selected={selectedItem}
            />}
        </div>
    )
}

export default WorkspaceSelector;
