import asana from 'asana';
import './App.css';
import {useState, useEffect} from 'react';
import AppBody from "./AppBody";
import Loader from "./Loader";
import { Button } from '@livechat/design-system';
import axios from 'axios';
import { Toast} from "@livechat/design-system";

const ToastWrapper = ({toastVisible, toggleToastVisible, toastText}) => {
    useEffect(()=>{
        setTimeout(()=>{toggleToastVisible(!toastVisible)},2500)
    })
    return(
        <div style={{position:"absolute", top:"20", width:"100%", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
            <Toast variant='success' removable onClose={()=>{toggleToastVisible(!toastVisible)}}>{toastText}</Toast>
        </div>
    )
}

function App() {

    const [selectedWorkspace, setSelectedWorkspace] = useState(null)
    const [workspaces, setWorkspaces] = useState([])
    const [tasksList, setTasksList] = useState([]);
    const [isTasksFetchCompleted, setIsTasksFetchCompleted] = useState(false)
    const [workspacesLoader, setWorkspacesLoader] = useState(true)
    const [isShowMoreVisible, setIsShowMoreVisible] = useState(false)
    const [userProfile, setUserProfile] = useState({})
    const [taskModalOpen, setTaskModalOpen] = useState(false)
    const [createTaskModalOpen, toggleCreateTaskModal] = useState(false)
    const [toastVisible, toggleToastVisible] = useState(false)
    const [toastText, setToastText] = useState('')
    const [asanaAuthenticated, setAsanaAuthenticated] = useState(false)
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [usersList, setUsersList] = useState([])

    let redirectUri;
    redirectUri = window.location.protocol + '//' + window.location.hostname
    if(window.location.port){
        redirectUri = redirectUri+':'+window.location.port
    }
    useEffect(()=>{
        document.title ='LiveChat Asana App'
    }, [])
    const asanaAuthUrl = `https://app.asana.com/-/oauth_authorize?client_id=1200055925941684&redirect_uri=${redirectUri}&response_type=code&scope=default`
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');
        if(localStorage.getItem('personalToken') && localStorage.getItem('expirationTime')>Date.now()){
            console.log('Token still valid')
            setAsanaAuthenticated(true)
            getWorkspaces(localStorage.getItem('personalToken'));
            return
        } else {
            console.log('Token expired')
            localStorage.removeItem('expirationTime')
            localStorage.removeItem('personalToken')
        }
        if(authCode !==null){
            setIsLoggingIn(true)
            const CodeExchangeUrl = `https://lc-asana-backend.herokuapp.com/auth/?code=${authCode}&code_type=authorization_code&redirect_url=${redirectUri}`
            let payload = {
            'code': authCode
          };
          axios.post(
              CodeExchangeUrl,
            payload).then(
                response => {
                    let keys = Object.keys(response.data)
                    let responseString = '';
                    keys.forEach((element)=>{
                        responseString = responseString+response.data[element]
                        }
                    )
                    const token = JSON.parse(responseString).access_token
                    const expirationTime = Date.now() + JSON.parse(responseString).expires_in
                    localStorage.setItem('personalToken', token);
                    localStorage.setItem('expirationTime', expirationTime);
                    setAsanaAuthenticated(true)
                    setIsLoggingIn(false)
                    window.opener.location.reload();
                    window.close();
                  }
            ).catch(error => {
                console.log(error);
              setIsLoggingIn(false)
            })
        } else {
            localStorage.removeItem('personalToken')
        }
    },[redirectUri])

    const getWorkspaces = async (token) => {
        let tempList = []
        const client = asana.Client.create().useAccessToken(token);
        try {
            await client.users.me().then(
                (me)=>{
                    setUserProfile(me); me.workspaces.forEach((value) => {tempList.push({key: value.name, props: {name: value.name, value: value.gid}})})
                    setWorkspaces(tempList)
                    localStorage.setItem('userId', me.gid)
                    localStorage.setItem('userName', me.name)
                })
        } finally {
            setWorkspacesLoader(false);
            localStorage.removeItem('nextPageOffset')
            localStorage.removeItem('workspaceId')
        }
    }

    const getTasksList = (reset) => {
        setIsShowMoreVisible(false);
        if(reset) {
            localStorage.removeItem('tasksListScrollDepth')
            localStorage.removeItem('nextPageOffset')
            setTasksList([])
        }
        let currentOffset = localStorage.getItem('nextPageOffset')
        setIsTasksFetchCompleted(false)
        const personalToken = localStorage.getItem('personalToken');
        const client = asana.Client.create().useAccessToken(personalToken);
        const fields = ['name', 'due_on', 'due_at', 'start_on', 'created_at', 'completed', 'completed_at', 'membership', 'notes', 'assignee']
        const params = {
            assignee: userProfile.gid,
            workspace: localStorage.getItem('workspaceId'),
            opt_fields: fields,
            opt_pretty: true
        }
        if(currentOffset){
            params['offset'] = currentOffset
        }
        client.tasks.getTasks(params)
        .then((result) => {
            if (currentOffset) {
                setTasksList(tasksList.concat(result.data))
            } else {
                setTasksList(result.data)
            }
            if(result._response.next_page){
                localStorage.setItem('nextPageOffset', result._response.next_page.offset)
                setIsShowMoreVisible(true);
            } else {
                localStorage.removeItem('nextPageOffset');
            }
            setIsTasksFetchCompleted(true);
        })
    }
    return (
        <div className="app__wrapper">
        {toastVisible ?
        <ToastWrapper toastVisible={toastVisible} toggleToastVisible={toggleToastVisible} toastText={toastText}/>
        : null}
            {asanaAuthenticated ?
                <AppBody asanaAuthenticated={asanaAuthenticated} toastText={toastText} userProfile={userProfile}
                         isShowMoreVisible={isShowMoreVisible} workspaces={workspaces} setWorkspaces={setWorkspaces}
                         workspacesLoader={workspacesLoader} selectedWorkspace={selectedWorkspace} getWorkspaces={getWorkspaces}
                         setSelectedWorkspace={setSelectedWorkspace} getTasksList={getTasksList} tasksList={tasksList}
                         setTasksList={setTasksList} isTasksFetchCompleted={isTasksFetchCompleted}
                         taskModalOpen={taskModalOpen} setTaskModalOpen={setTaskModalOpen}
                         createTaskModalOpen={createTaskModalOpen} toggleCreateTaskModal={toggleCreateTaskModal}
                         toastVisible={toastVisible} toggleToastVisible={toggleToastVisible}
                         setToastText={setToastText} usersList={usersList} setUsersList={setUsersList}
                />
                         :
                null
            }
            {!asanaAuthenticated && !isLoggingIn ?
                <div className='component__wrapper flex--column authenticate-button__container'>
                    <div>Log in to your Asana account to start using the application</div>
                    <Button kind="secondary"
                            onClick={()=>{window.open(asanaAuthUrl, 'test',
                        `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                                width=400,height=800,left=-1000,top=-1000`)}}>
                        Authenticate with Asana
                    </Button>
                </div>
                :
                null
            }
            {isLoggingIn ?
            <Loader label={'Signing in'}/>
            :
            null
            }

        </div>
            );
    }

export default App;
